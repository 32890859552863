@import './themes';

@mixin nb-overrides() {
  ol,
  ul,
  dl {
    margin-bottom: 0;
  }

  .form-control[type='checkbox'] {
    height: calc(0.5em + 0.75rem + 2px);
  }

  .checkbox-field {
    display: flex;
    align-items: center;
    margin: 10px 0;

    input {
      height: calc(0.75rem);
      width: calc(0.75rem);
      margin-right: 10px;
    }

    span {
      flex-grow: 1;
    }
  }

  nb-layout.window-mode,
  nb-layout .layout,
  nb-sidebar {
    background: transparent;
  }

  nb-layout-header {
    background-color: nb-theme(nav-header-background-color);
  }

  nb-layout .layout .layout-container .content {
    background-color: nb-theme(background-basic-color-3);
    position: relative;
  }

  [nbButton].size-tiny {
    min-width: 80px;
  }

  [nbInput].size-tiny {
    font-weight: nb-theme(card-text-font-weight);
  }

  [nbInput].status-basic {
    background-color: transparent;
    &:hover {
      background-color: transparent;
      border: 1px solid nb-theme(black);
    }
    &:hover:focus {
      background-color: transparent;
    }
  }

  nb-select.appearance-outline.status-basic .select-button{
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    &:hover:focus {
      background-color: transparent;
    }
  }

    ng2-smart-table {

      th.ng2-smart-actions-title-add a,
      .ng2-smart-actions a.ng2-smart-action {
        font-size: nb-theme(text-subtitle-font-size) !important;
      }

      thead th:first-child {
        width: 150px;
        input[type="checkbox"] {
          height: 18px;
          width: 18px;

        }
      }

      tr td {
        font-size: nb-theme(smart-table-font-size) !important;
        padding: nb-theme(smart-table-padding) !important;
      }

      .ng2-smart-title {
        font-weight: normal;
        font-size: nb-theme(smart-table-font-size) !important;
      }

      th {
        text-align: center !important;
        padding-right: 0.75rem !important;
      }
    }

  .card-body {
    padding: nb-theme(card-padding);
  }

  .table {
    background-color: nb-theme(color-basic-100);
    border-left: 1px solid nb-theme(smart-table-border-color);
    border-bottom: 1px solid nb-theme(smart-table-border-color);

    thead th {
      color: nb-theme(color-basic-600);
      font-weight: normal;
      border-bottom: none;
    }

    th,
    td {
      padding: nb-theme(smart-table-padding);
      border-right: 1px solid nb-theme(smart-table-border-color);
    }

    td {
      border-color: nb-theme(color-basic-400);
      vertical-align: middle;
    }
  }

  .table-hover tbody tr:hover {
    color: nb-theme(color-primary-400);
  }

  .btn {
    font-size: nb-theme(text-paragraph-font-size);
  }

  nb-context-menu nb-menu .menu-item {
    a {
      padding: nb-theme(context-menu-item-padding);
      color: nb-theme('card-text-color');
    }

    a:hover {
      background-color: nb-theme(menu-item-hover-text-color);
      color: nb-theme(color-primary-100) !important;

      nb-icon {
        color: nb-theme(color-primary-100) !important;
      }
    }
  }

  nb-toggle {
    .toggle {
      height: 24px;
    }

    .toggle-label {
      margin: 0;
    }

    .toggle-switcher {
      height: 23px;
    }
  }
}

.pac-container {
  z-index: 10000000 !important;
}

.pull-right {
  float: right;
}

.link {
  cursor: pointer;
  text-decoration: underline !important;
}
.mat-mdc-select{
  font-size: 10px !important;
}

.mat-mdc-option{
  font-size: 10px !important;
  min-height: 32px !important;
}
mat-form-field{
  .mat-mdc-icon-button.mat-mdc-button-base{
    height: 24px !important;
    width: 24px !important;
    padding: 0px;
    margin-right: 10px;
  }
}
nb-dialog-container {
  max-width: 100vw;
  nb-card-header{
    background: #edf2ed;
    color: #333333 !important;

    button{
      margin-left: 10px;
    }
  }
  nb-card-footer{
    padding: 10px;
    background: #f4f3ff;

    button{
      margin-right: 10px;
    }
  }

  nb-card {
    min-width: 500px;
  }

  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    position: relative;

    label {
      width: 25%;
      flex: 0 0 25%;
    }

    nb-toggle {
      width: 75%;

      label {
        width: auto;
      }
    }


  }
}

text-editor {
  display: inline-flex;
  width: 100%;
  border: 1px solid nb-theme(select-outline-basic-border-color);
  background-color: nb-theme(select-outline-basic-background-color);
  border-radius: nb-theme(select-rectangle-border-radius);
}

custom-select {
  display: inline-flex;
  width: 100%;
}

.ng-select.custom {
  display: inline-flex;
  width: 100%;

  .ng-dropdown-panel {
    min-width: 100%;
    width: auto;
  }

  .ng-select-container {
    min-height: 21px;
    height: 21px;
    outline: none;
    border-color: nb-theme(select-outline-basic-border-color);
    background-color: nb-theme(select-outline-basic-background-color);
    border-radius: nb-theme(select-rectangle-border-radius);

    &:hover {
      background-color: nb-theme(select-outline-basic-hover-background-color);
      border-color: nb-theme(select-outline-basic-hover-border-color);
    }
  }

  &.ng-select-focused .ng-select-container {
    box-shadow: none;
    border-color: nb-theme(select-outline-basic-border-color);
  }

  &.ng-select-clearable .ng-clear-wrapper {
    display: none;
  }

  &.ng-select-clearable.ng-select-opened .ng-clear-wrapper {
    display: flex;
    width: 10px;
  }

  &.ng-select-clearable.ng-select-opened .ng-clear-wrapper .ng-clear {
    font-size: 16px;
  }

  & .ng-arrow-wrapper {
    top: 1px;
  }

  &.dropdown-small .ng-option {
    font-size: 0.5rem;
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container .ng-value .ng-value-icon {
      background-color: #d1e8ff;
    }

    .ng-option {
      font-size: 0.6rem;
      padding: 0 5px;
      display: flex;
      align-items: center;
      height: 24px;

      input {
        margin-right: 5px;
      }
    }
  }

  &.scroll-host {
  }
}

.grid-column {
  position: relative;
  display: flex;
  align-items: center;
  margin: 3px;
  padding: 3px 0px 3px 12px;
  border: 1px solid;
  flex: 0 0 200px;
  height: 20px;
  cursor: all-scroll;

  &.excluded {
    color: nb-theme(color-basic-600);
    border: 1px solid nb-theme(color-basic-400);
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;

    &.target {
      margin: 0 0 20px 0;
      border: 1px solid nb-theme(color-basic-600);
      min-height: 50px;
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__status {
    cursor: pointer;
  }

  & .custom-table__cell-content {
    padding: 0 !important;
  }
}

nb-icon.drag-handle {
  height: 10px;
  position: absolute;
  left: -3px;
  top: calc(50% - 5px);
}

.highlighted {
  color: nb-theme(color-success-600) !important;
  font-weight: bold;
}

nb-option-list .option-list,
.ng-select.custom .scroll-host,
.custom-scroll {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: nb-theme(scrollbar-width);
    width: nb-theme(scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: nb-theme(scrollbar-background-color);
  }

  &::-webkit-scrollbar-thumb {
    background: nb-theme(scrollbar-color);
  }
}

nb-route-tabset {
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    height: nb-theme(scrollbar-width);
    width: nb-theme(scrollbar-width);
  }

  ::-webkit-scrollbar-track {
    background: nb-theme(scrollbar-background-color);
  }

  ::-webkit-scrollbar-thumb {
    background: nb-theme(scrollbar-color);
  }
}

/*------------------*/
/* Dragula styling */
/*----------------*/

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.cdk-global-scrollblock {
  position: static;
  overflow-y: auto;
}

// nb-dialog-container {
//   nb-card-footer{
//     justify-content: flex-end;
//     display: flex;
//   }
// }

// Email fields error messages
.field{
  position: relative;
}
.error_message {
  color: nb-theme(color-danger-500);
  position: absolute;
  bottom: -5px;
  margin-bottom: 0px;
}
.close-icon {
  float: right;
  cursor: pointer;
}

nb-spinner{
  z-index: 900 !important;
}

.mat-mdc-form-field-infix {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  height: auto !important;
  min-height: auto !important;
}

.mat-mdc-form-field-input-control {
  //font-size: 11px !important;
  // line-height: 0px !important;
  font-size: 10px !important;
  height: auto !important;
}

.mat-mdc-text-field-wrapper  {
  background-color: white !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #f44336 !important;
}

.ng-dropdown-panel.ng-select-bottom {
  z-index: 1000000 !important;
}

.required {
  &:after {
    content: "*";
    position: relative;
    color: #F1416C;
    padding-left: 0.25rem;
    font-weight: bold;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 1em !important;
  font-size: 100% !important;
}

.manual-order {
  background-color:nb-theme(color-primary-400);
  color: #ffffff;
}

.manage-order {
  background-color: nb-theme(color-info-400);
}

.custom {
  background-color: nb-theme(color-warning-400);
}

.daily-product-estimating {
  background-color: nb-theme(color-danger-600);
  color: #ffffff;
}

.proposal-created {
  background-color: nb-theme(color-danger-800);
  color: white;
}

.pricing-request-sent {
  background-color: nb-theme(color-primary-200);
}

.followup-request-processing {
  background-color: nb-theme(color-warning-300);
}

.pricing-received {
  background-color: nb-theme(color-info-300);
}

.proposal-sent {
  background-color: nb-theme(color-primary-transparent-300);
}

.documents-signed {
  background-color:nb-theme(color-warning-500);
}

.waiting-for-signature {
  background-color:nb-theme(color-danger-400);
}

.waiting-for-confirmation {
  background-color: nb-theme(color-success-800);
}

.processing-document-request {
  background-color: nb-theme(color-danger-200);
}

.not-reported {
  background-color:nb-theme(color-info-300);
}

.confirmed {
  background-color: nb-theme(color-success-800);
  color: white;
}

.badge {
  color: #ffffff;

  &.active {
    background-color:nb-theme(color-primary-400);
  }

  &.disabled {
    background-color: nb-theme(color-danger-400);
  }
}

.angular-editor-textarea {
  a {
    cursor: pointer;
  }
}

.angular-editor-toolbar {
  input[type=color]:not(.color-picker) {
    display: initial !important;
    position: absolute;
    visibility: hidden;
    border: none;
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
  }
}
