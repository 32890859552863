// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes/dark';

@import '@nebular/theme/styles/global/breakpoints';
@import 'bootstrap/scss/mixins/breakpoints';

$nb-enable-css-custom-properties: true;
$nb-themes: nb-register-theme(
  (
    color-primary-100: #daf3f9,
    color-primary-200: #b8e5f4,
    color-primary-300: #8cc5de,
    color-primary-400: #669fbe,
    color-primary-500: #396e94,
    color-primary-600: #29567f,
    color-primary-700: #1c416a,
    color-primary-800: #122d55,
    color-primary-900: #0a2047,
    color-primary-transparent-100: rgba(57, 110, 148, 0.08),
    color-primary-transparent-200: rgba(57, 110, 148, 0.16),
    color-primary-transparent-300: rgba(57, 110, 148, 0.24),
    color-primary-transparent-400: rgba(57, 110, 148, 0.32),
    color-primary-transparent-500: rgba(57, 110, 148, 0.4),
    color-primary-transparent-600: rgba(57, 110, 148, 0.48),
    color-success-100: #d1fde0,
    color-success-200: #a4fbcb,
    color-success-300: #76f5ba,
    color-success-400: #52ecb4,
    color-success-500: #1ee0ac,
    color-success-600: #15c0a3,
    color-success-700: #0fa196,
    color-success-800: #097f81,
    color-success-900: #055f6b,
    color-success-transparent-100: rgba(30, 224, 172, 0.08),
    color-success-transparent-200: rgba(30, 224, 172, 0.16),
    color-success-transparent-300: rgba(30, 224, 172, 0.24),
    color-success-transparent-400: rgba(30, 224, 172, 0.32),
    color-success-transparent-500: rgba(30, 224, 172, 0.4),
    color-success-transparent-600: rgba(30, 224, 172, 0.48),
    color-info-100: #cffbf3,
    color-info-200: #a1f7ef,
    color-info-300: #6fe9e7,
    color-info-400: #49ccd4,
    color-info-500: #17a2b8,
    color-info-600: #107f9e,
    color-info-700: #0b6084,
    color-info-800: #07456a,
    color-info-900: #043258,
    color-info-transparent-100: rgba(23, 162, 184, 0.08),
    color-info-transparent-200: rgba(23, 162, 184, 0.16),
    color-info-transparent-300: rgba(23, 162, 184, 0.24),
    color-info-transparent-400: rgba(23, 162, 184, 0.32),
    color-info-transparent-500: rgba(23, 162, 184, 0.4),
    color-info-transparent-600: rgba(23, 162, 184, 0.48),
    color-warning-100: #fff7cd,
    color-warning-200: #ffed9b,
    color-warning-300: #ffe16a,
    color-warning-400: #ffd545,
    color-warning-500: #ffc107,
    color-warning-600: #dba005,
    color-warning-700: #b78103,
    color-warning-800: #936402,
    color-warning-900: #7a4f01,
    color-warning-transparent-100: rgba(255, 193, 7, 0.08),
    color-warning-transparent-200: rgba(255, 193, 7, 0.16),
    color-warning-transparent-300: rgba(255, 193, 7, 0.24),
    color-warning-transparent-400: rgba(255, 193, 7, 0.32),
    color-warning-transparent-500: rgba(255, 193, 7, 0.4),
    color-warning-transparent-600: rgba(255, 193, 7, 0.48),
    color-danger-100: #fee4db,
    color-danger-200: #fec4b8,
    color-danger-300: #fc9c94,
    color-danger-400: #f9797b,
    color-danger-500: #f64e60,
    color-danger-600: #d33957,
    color-danger-700: #b1274f,
    color-danger-800: #8e1845,
    color-danger-900: #760e3f,
    color-danger-transparent-100: rgba(246, 78, 96, 0.08),
    color-danger-transparent-200: rgba(246, 78, 96, 0.16),
    color-danger-transparent-300: rgba(246, 78, 96, 0.24),
    color-danger-transparent-400: rgba(246, 78, 96, 0.32),
    color-danger-transparent-500: rgba(246, 78, 96, 0.4),
    color-danger-transparent-600: rgba(246, 78, 96, 0.48),
    color-overlay: rgba(0, 0, 0, 0.2),
    color-note-alternative: #979797,
    shadow: 0 0.5rem 1rem 0 rgba(25, 30, 51, 0.24),
    footer-height: 0,
    footer-background-color: background-basic-color-3,
    header-text-color: text-alternate-color,
    actions-text-color: text-alternate-color,
    menu-text-color: text-alternate-color,
    context-menu-item-padding: 0.1rem 1rem,
    base-font-size-1: 0.54rem,
    base-font-size-2: 0.67rem,
    base-font-size-3: 0.72rem,
    text-paragraph-font-size: base-font-size-1,
    text-caption-2-font-size: base-font-size-1,
    text-subtitle-2-font-size: base-font-size-1,
    text-button-tiny-font-size: base-font-size-1,
    text-subtitle-font-size: base-font-size-2,
    route-tabset-tab-text-font-size: base-font-size-2,
    text-heading-6-font-size: base-font-size-2,
    text-heading-2-font-size: 0.8rem,
    route-tabset-tab-padding: 0.5rem 1rem,
    card-padding: 0.3rem,
    card-text-line-height: 0.7rem,
    nav-header-background-color: #06263e,
    smart-table-padding: 0.3rem 0.75rem,
    smart-table-filter-padding: 5px 10px,
    smart-table-border-color: #dee2e6,
    select-min-width: 0,
    spinner-basic-background-color: transparent,
    calendar-day-cell-width: 25px,
    calendar-day-cell-height: 25px,
    calendar-width: auto,
    white: #ffffff,
    black: #000000,
    disable-bg: #e4e7ed,
    disable: #bbc2d4,
  ),
  darkgradient,
  default
);

body {
  background: url(/assets/gradient-bg.jpg);
  background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}
