@use '@angular/material' as mat;
@include mat.core();

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/global/breakpoints';
@import 'bootstrap/scss/mixins/breakpoints';
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/all';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::ng-deep .ng2-smart-actions-title {
  width: 200px !important;
}

::ng-deep .ng2-smart-actions {
  width: 200px !important;
  height: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;

  ng2-st-tbody-custom {
    display: flex;
  }

  .ng2-smart-action {
    background-color: #efefef !important;
    padding: 0.3125rem 0.625rem !important;
    border-style: solid !important;
    border-width: 0.0625rem !important;
    border-color: #000000 !important;
    border-radius: 0.25rem !important;
    color: #000000 !important;
    height: 20px !important;
    margin: 0 5px !important;
  }
}

.status-wrapper {
  display: flex;
  gap: 8px;
  justify-content: start;
  align-items: center;

  .status-indicator {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  background-color: nb-theme(color-warning-default);

    &.success {
      background-color: nb-theme(color-success-default);
    }

    &.primary {
      background-color: nb-theme(color-primary-default);
    }

    &.error {
      background-color: nb-theme(color-danger-default);
    }
  }
}

.field {
  margin: 20px 0 !important;

  .is-invalid {
    border-color: #F1416C;
    padding-right: calc(1.5em + 1.5rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.375rem) center !important;
    background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem) !important;

    &:focus {
      border-color: #F1416C !important;
    }

    &:hover {
      border-color: #F1416C !important;
    }
  }

  .error {
    position: absolute;
  }
}

nb-toast {
  .content-container {
    display: flex;
    justify-content: center;
  }
}
